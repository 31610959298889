import { getAbTestV2 } from 'dibs-ab-tests/src/clientAbTestV2';
import { useClientState } from 'dibs-react-hooks/src/useClientState';
import { isUserInEurope } from 'dibs-regional-info/src/regionalInfoHelpers';

export const ITEM_REGION_ON_TIlE = 'ItemRegionOnTile';

export const useShowItemLocationBadge = (): boolean => {
    const isClient = useClientState();
    if (isClient) {
        const isInEurope = isUserInEurope();
        const isRegionOnTileTestAbTest = getAbTestV2(ITEM_REGION_ON_TIlE)?.variant === 'variant';
        return isInEurope && isRegionOnTileTestAbTest;
    }
    return false;
};

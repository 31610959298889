/**
 * @generated SignedSource<<3068f4eadddce226b793ec06c4c85ce0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedTrackingFunctions_viewer$data = {
  readonly noSoldItemsOnCollectionPage: boolean | null;
  readonly " $fragmentType": "SbSharedTrackingFunctions_viewer";
};
export type SbSharedTrackingFunctions_viewer$key = {
  readonly " $data"?: SbSharedTrackingFunctions_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedTrackingFunctions_viewer">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "SbSharedTrackingFunctions_viewer"
};

(node as any).hash = "0cc4cd60b959c80387acfe2c29971f25";

export default node;
